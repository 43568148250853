<template>
  <div style="text-align: center">
    <img :src="require('@/assets/401.png')" style="max-width: 70%; padding: 200px 50px" />
  </div>
</template>

<script>
export default {

}
</script>

<style scoped></style>